/*
Markdown Gallery
-- v1.0 2016
-- Created by Lee Penney
-- Released under GPLv3
*/

function md_gallery(config) {
    var config = config || {},
        list_type = config.list_type || 'ul',
        class_name = config.class_name || 'gallery',
        tag_type = config.tag_type || 'div';

    function find_lists(list_type) {
        var lists = document.getElementsByTagName(list_type),
            matching_lists = [];
        for (var i = 0; i < lists.length; i++) {
            var list_elements = lists[i].children;
            var total_matches = 0;
            for (var c = 0; c < list_elements.length; c++) {
                if (
                    !list_elements[c].textContent.length &&
                    (list_elements[c].firstChild.tagName == 'A' || list_elements[c].firstChild.tagName == 'IMG') &&
                    (!list_elements[c].firstChild.firstChild ||
                        (list_elements[c].firstChild.firstChild && list_elements[c].firstChild.firstChild.tagName == 'IMG'))
                ) {
                    total_matches++;
                }
            }
            if (total_matches == list_elements.length) {
                matching_lists[matching_lists.length] = lists[i];
            }
        }
        return matching_lists;
    }

    function prepend_tag(img_lists, list_tag, prepend_tag, class_name) {
        for (var i = 0; i < img_lists.length; i++) {
            add_figure_tags(img_lists[i]);
            wrap_tag(img_lists[i], prepend_tag, class_name);
            strip_tag(img_lists[i], 'li');
            strip_tag(img_lists[i].parentNode, list_tag);
        }
    }

    function append_caption(el) {
        if (
            (el.tagName == 'A' &&
                el.firstChild.tagName == 'IMG' &&
                el.firstChild.hasAttribute('alt') &&
                el.firstChild.getAttribute('alt').length > 0) ||
            (el.tagName == 'IMG' && el.hasAttribute('alt') && el.getAttribute('alt').length > 0)
        ) {
            var caption = document.createElement('figcaption');
            try {
                caption.textContent = el.firstChild.getAttribute('alt');
                el.appendChild(caption);
            } catch (e) {
                caption.textContent = el.getAttribute('alt');
                el.parentNode.appendChild(caption);
            }
        }
    }

    function strip_tag(el, tag_type) {
        var start_tag_regex = new RegExp('<' + tag_type + '>', 'gi');
        var end_tag_regex = new RegExp('</' + tag_type + '>', 'gi');
        el.innerHTML = el.innerHTML.replace(start_tag_regex, '').replace(end_tag_regex, '');
    }

    function add_figure_tags(img_list) {
        var list_elements = img_list.children;
        for (var i = 0; i < list_elements.length; i++) {
            append_caption(list_elements[i].firstChild);
            wrap_tag(list_elements[i], 'figure');
        }
    }

    function wrap_tag(el, tag_type, class_name) {
        var wrap = document.createElement(tag_type);
        if (class_name) {
            class_name += ' gallery-cols-' + el.children.length;
            wrap.setAttribute('class', class_name);
        }
        el.parentNode.replaceChild(wrap, el);
        wrap.appendChild(el);
    }

    var found_img_lists = find_lists(list_type);
    if (found_img_lists.length) {
        prepend_tag(found_img_lists, list_type, tag_type, class_name);
    }
}


function is_youtubelink(url) {
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (url.match(p)) ? RegExp.$1 : false;
}
function is_imagelink(url) {
    var p = /([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i;
    return (url.match(p)) ? true : false;
}
function is_vimeolink(url,el) {
    var id = false;
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status == 200) {
                var response = JSON.parse(xmlhttp.responseText);
                id = response.video_id;
                console.log(id);
                el.classList.add('lightbox-vimeo');
                el.setAttribute('data-id',id);

                el.addEventListener("click", function(event) {
                    event.preventDefault();
                    document.getElementById('lightbox').innerHTML = '<a id="close"></a><a id="next">&rsaquo;</a><a id="prev">&lsaquo;</a><div class="videoWrapperContainer"><div class="videoWrapper"><iframe src="https://player.vimeo.com/video/'+el.getAttribute('data-id')+'/?autoplay=1&byline=0&title=0&portrait=0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>';
                    document.getElementById('lightbox').style.display = 'block';

                    setGallery(this);
                });
            }
            else if (xmlhttp.status == 400) {
                alert('There was an error 400');
            }
            else {
                alert('something else other than 200 was returned');
            }
        }
    };
    xmlhttp.open("GET", 'https://vimeo.com/api/oembed.json?url='+url, true);
    xmlhttp.send();
}
function setGallery(el) {
    var elements = document.body.querySelectorAll(".gallery");
    elements.forEach(element => {
        element.classList.remove('gallery');
	});
	if(el.closest('ul, p')) {
		var link_elements = el.closest('ul, p').querySelectorAll("a[class*='lightbox-']");
		link_elements.forEach(link_element => {
			link_element.classList.remove('current');
		});
		link_elements.forEach(link_element => {
			if(el.getAttribute('href') == link_element.getAttribute('href')) {
				link_element.classList.add('current');
			}
		});
		if(link_elements.length>1) {
			document.getElementById('lightbox').classList.add('gallery');
			link_elements.forEach(link_element => {
				link_element.classList.add('gallery');
			});
		}
		var currentkey;
		var gallery_elements = document.querySelectorAll('a.gallery');
		Object.keys(gallery_elements).forEach(function (k) {
			if(gallery_elements[k].classList.contains('current')) currentkey = k;
		});
		if(currentkey==(gallery_elements.length-1)) var nextkey = 0;
		else var nextkey = parseInt(currentkey)+1;
		if(currentkey==0) var prevkey = parseInt(gallery_elements.length-1);
		else var prevkey = parseInt(currentkey)-1;
		document.getElementById('next').addEventListener("click", function() {
			gallery_elements[nextkey].click();
		});
		document.getElementById('prev').addEventListener("click", function() {
			gallery_elements[prevkey].click();
		});
	}
}

document.addEventListener("DOMContentLoaded", function() {

    if (document.getElementById('datasheets_list') === false) {

        //create lightbox div in the footer
        var newdiv = document.createElement("div");
        newdiv.setAttribute('id',"lightbox");
        document.body.appendChild(newdiv);

        //add classes to links to be able to initiate lightboxes
        var elements = document.querySelectorAll('a');
        elements.forEach(element => {
            var url = element.getAttribute('href');
            if(url) {
                if(url.indexOf('vimeo') !== -1 && !element.classList.contains('no-lightbox')) {
                    is_vimeolink(url,element);
                }
                if(is_youtubelink(url) && !element.classList.contains('no-lightbox')) {
                    element.classList.add('lightbox-youtube');
                    element.setAttribute('data-id',is_youtubelink(url));
                }
                if(is_imagelink(url) && !element.classList.contains('no-lightbox')) {
                    element.classList.add('lightbox-image');
                    var href = element.getAttribute('href');
                    var filename = href.split('/').pop();
                    var split = filename.split(".");
                    var name = split[0];
                    element.setAttribute('title',name);
                }
            }
        });

        //remove the clicked lightbox
        document.getElementById('lightbox').addEventListener("click", function(event) {
            if(event.target.id != 'next' && event.target.id != 'prev'){
                this.innerHTML = '';
                document.getElementById('lightbox').style.display = 'none';
            }
        });

        //add the youtube lightbox on click
        var elements = document.querySelectorAll('a.lightbox-youtube');
        elements.forEach(element => {
            element.addEventListener("click", function(event) {
                event.preventDefault();
                document.getElementById('lightbox').innerHTML = '<a id="close"></a><a id="next">&rsaquo;</a><a id="prev">&lsaquo;</a><div class="videoWrapperContainer"><div class="videoWrapper"><iframe src="https://www.youtube.com/embed/'+this.getAttribute('data-id')+'?autoplay=1&showinfo=0&rel=0"></iframe></div>';
                document.getElementById('lightbox').style.display = 'block';

                setGallery(this);
            });
        });

        //add the image lightbox on click
        var elements = document.querySelectorAll('a.lightbox-image');
        elements.forEach(element => {
            element.addEventListener("click", function(event) {
                event.preventDefault();
                document.getElementById('lightbox').innerHTML = '<a id="close"></a><a id="next">&rsaquo;</a><a id="prev">&lsaquo;</a><div class="img" style="background: url(\''+this.getAttribute('href')+'\') center center / contain no-repeat;" title="'+this.getAttribute('title')+'" ><img src="'+this.getAttribute('href')+'" alt="'+this.getAttribute('title')+'" /></div><span>'+this.getAttribute('title')+'</span>';
                document.getElementById('lightbox').style.display = 'block';

                setGallery(this);
            });
        });

    }

});
