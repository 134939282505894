import $ from './jquery-plugins';

require('bootstrap');
import 'bootstrap/dist/css/bootstrap.css';

require('jquery-ui');
// jQuery UI CSS is loaded from CDN

require('jquery.appear');
require('form-serializer');
require('imagesloaded');
require('isotope-layout');
require('wowjs');
require('mmenu-js');
import 'mmenu-js/dist/mmenu.css';

import Swiper from 'swiper';
import 'swiper/css/bundle';

// Make Swiper available globally
window.Swiper = Swiper;

import '../../assets/css/slider/hero-slider.css';
import "../src/vendor/gallery.js";
window.magnificPopup = require('magnific-popup');
import 'magnific-popup/dist/magnific-popup.css';

// Font Awesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';

// IcoFont
import 'icofont/dist/icofont.min.css';

import '../../assets/css/404.css';
import '../../assets/css/accordion.css';
import '../../assets/css/animate.min.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/common.css';
import '../../assets/css/flaticon.css';
import '../../assets/css/meanmenu.css';
import '../../assets/css/odometer.css';
import '../../assets/css/responsive.css';
import '../../assets/css/slick.css';
import '../../assets/css/style.css';

// Import Owl Carousel from npm
require('owl.carousel');
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';